<template>
  <div>
    <el-steps :active="active" align-center finish-status="success">
      <el-step title="第一步" description="基本信息"></el-step>
      <el-step title="第二步" description="导入价格"></el-step>
      <el-step title="第三步" description="编辑报价"></el-step>
    </el-steps>
    <div class="headerbut">
      <div v-if="active == 3"><el-button type="warning">保存</el-button></div>
      <div v-else>
        <el-button type="warning" @click="next">下一步</el-button>
      </div>
    </div>
    <div class="frombox" v-if="active == 1">
      <el-form
        ref="form"
        :model="form"
        label-width="100px"
        label-position="left"
        :inline="true"
      >
        <el-form-item label="客户报价编号" class="formli">
          <el-input v-model="form.name" class="inpbox"></el-input>
        </el-form-item>
        <el-form-item label="客户报价名称" class="formli">
          <el-input v-model="form.name" class="inpbox"></el-input>
        </el-form-item>
        <el-form-item label="客户报价等级" class="formli">
          <el-select
            v-model="form.region"
            placeholder="请选择活动区域"
            class="inpbox"
          >
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报价风格" class="formli">
          <el-checkbox-group v-model="form.type">
            <el-checkbox label="横向" name="type"></el-checkbox>
            <el-checkbox label="纵向" name="type"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="生效时间" class="formli">
          <el-input v-model="form.name" class="inpbox"></el-input>
        </el-form-item>
        <el-form-item label="截止时间" class="formli">
          <el-input v-model="form.name" class="inpbox"></el-input>
        </el-form-item>
        <el-form-item label="新建时间" class="formli">
          <el-input v-model="form.name" class="inpbox"></el-input>
        </el-form-item>
        <el-form-item label="新建人" class="formli">
          <el-input v-model="form.name" class="inpbox"></el-input>
        </el-form-item>

        <el-form-item label="备注" class="formli">
          <el-input
            v-model="form.name"
            class="inpbox1"
            type="textarea"
            :rows="2"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="active == 2">
      <div class="title">
        提示： 导入报价将删除原有报价信息
        ，从Excel中直接拷贝，两个数字之间采用Tab键进行分割，两行之间采用分行进行分割
      </div>
      <div class="Pitbox"></div>
    </div>

    <div v-if="active == 3"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formInline: {
        user: "",
        region: "",
      },
      form: {
        //表单
        name: "",
        type: [],
        region: "",
      },
      active: 1, //步骤条
    };
  },
  methods: {
    //修改国家
    ModifyCountry() {
      this.$router.push("/PayableManagement/ModifyCountry");
    },
    //步骤条
    next() {
      if (this.active < 3) {
        this.active++;
      } else {
        this.active = 1;
      }
      //console.log(this.active);
    },
  },
};
</script>

<style scoped lang="scss">
.headerbut {
  width: 100%;
  padding: 30px 10%;
  box-sizing: border-box;
}
.frombox {
  min-width: 600px;
  width: 80%;
  padding: 30px 100px;
  box-sizing: border-box;
  margin: 20px auto;
  background-color: #f2f2f2;
  .headertitle {
    width: 100%;
    padding: 12px 30px;
    box-sizing: border-box;
    color: #606266;
  }
  .formli {
    padding: 0 40px;
    .inpbox {
      width: 190px;
      padding: 0 10px;
    }
    .inpbox1 {
      width: 600px;
      padding: 0 10px;
    }
  }
}
.partitionfrom {
  min-width: 600px;
  width: 80%;
  padding: 10px 0;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  background-color: #f2f2f2;
  .partitionfromli {
    padding: 0 40px;
    .partitioninpbox {
      width: 160px;
    }
  }
}
.partitionBut {
  width: 100%;
  padding: 30px 10%;
  box-sizing: border-box;
}
.box {
  width: 100%;
  padding: 15px 10%;
  box-sizing: border-box;
  margin-bottom: 30px;
}
.title {
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: center;
}
.Pitbox {
  width: 80%;
  border: 1px solid #606266;
  padding: 100px 30px;
  box-sizing: border-box;
  margin: 20px auto;
}
</style>